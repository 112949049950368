export const params = {
    header: {
        "position": "relative",
        "fullScreen": {
            "enable": false
        },
        "particles": {
            "number": {
                "value": 500,
                "density": {
                    "enable": true,
                    "value_area": 2000
                }
            },
            "color": {
                "value": ["#009999", "#f652a0"]
            },
            "shape": {
                "type": "character",
                "character": {
                    "value": [
                        "A", "B", "C", "D", "E", "zuverlässig", "pflichtbewusst", "kreativ", "terminbewusst", "F", "", "G", "H", "I", "J", "K", "L", "M",
                        "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",
                        "A", "B", "C", "D", "E", "F", "", "G", "H", "I", "J", "K", "L", "M",
                        "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"
                    ],
                    "font": "Arial",
                    "style": "",
                    "weight": "400"
                }
            },
            "opacity": {
                "value": 0.5,
                "random": false,
                "anim": {
                    "enable": true,
                    "speed": 0.5,
                    "opacity_min": 0.1,
                    "sync": false
                }
            },
            "size": {
                "value": 20,
                "random": true,
                "anim": {
                    "enable": true,
                    "speed": 12.181158184520175,
                    "size_min": 0.1,
                    "sync": true
                }
            },
            "line_linked": {
                "enable": false,
                "distance": 150,
                "color": "",
                "opacity": 0.5,
                "width": 1
            },
            "move": {
                "enable": true,
                "speed": 1,
                "direction": "none",
                "random": false,
                "straight": false,
                "out_mode": "bounce",
                "bounce": false,
                "attract": {
                    "enable": false,
                    "rotateX": 600,
                    "rotateY": 1200
                }
            }
        },
        "detectRetina": true
    },
    headerMobile: {
        "position": "relative",
        "fullScreen": {
            "enable": false
        },
        "particles": {
            "number": {
                "value": 500,
                "density": {
                    "enable": true,
                    "value_area": 2000
                }
            },
            "color": {
                "value": ["#f652a0"]
            },
            "shape": {
                "type": "character",
                "character": {
                    "value": [
                        "A", "B", "C", "D", "E", "zuverlässig", "pflichtbewusst", "kreativ", "terminbewusst", "F", "", "G", "H", "I", "J", "K", "L", "M",
                        "N", "O", "P", "Q", "R", "S", "U", "A", "B", "C", "D", "E", "A", "B", "C", "D", "E",
                    ],
                    "font": "Arial",
                    "style": "",
                    "weight": "400"
                }
            },
            "opacity": {
                "value": 0.5,
                "random": false,
                "anim": {
                    "enable": true,
                    "speed": 0.5,
                    "opacity_min": 0.1,
                    "sync": false
                }
            },
            "size": {
                "value": 20,
                "random": true,
                "anim": {
                    "enable": true,
                    "speed": 12.181158184520175,
                    "size_min": 0.1,
                    "sync": true
                }
            },
            "line_linked": {
                "enable": false,
                "distance": 150,
                "color": "",
                "opacity": 0.5,
                "width": 1
            },
            "move": {
                "enable": true,
                "speed": 1,
                "direction": "none",
                "random": false,
                "straight": false,
                "out_mode": "bounce",
                "bounce": false,
                "attract": {
                    "enable": false,
                    "rotateX": 600,
                    "rotateY": 1200
                }
            }
        },
        "detectRetina": true
    }
}