import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FaMoon, FaSun } from "react-icons/fa";
import { Link } from "react-router-dom";
import Home from "../../components/Home";
import About from "../../components/about/AboutMain";
import Portfolio from "../../components/PortfolioCreative";
import News from "../../components/News";
import ServiceMain from "../../components/service/ServiceMain";
import Contact from "../../components/Contact";
import CopyRight from "../../components/CopyRight";
import PageTitle from "../../components/PageTitle";
import { ParticlesBg } from "../../components/ParticlesBG";
import DataSec from "../../components/datenschutz/dataSec";
import { params } from "../../components/jsons";
import axios from "axios";
import { environment } from "../../environment";

const HomeLight = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const previousPage = document.referrer;
    console.log(previousPage)

  }, [])

  useEffect(() => {
    document.body.classList.toggle("dark", isDarkMode);
    localStorage.setItem("isDarkModeEnabled", isDarkMode);
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <>
      <PageTitle title="Uta Koßmagk- Redaktionsbüro" />
      {/* End page title for seo */}

      {/*<button className="theme-switcher-label" onClick={toggleDarkMode}>
        {isDarkMode ? (
          <>
            <FaSun />
          </>
        ) : (
          <>
            <FaMoon />
          </>
        )}
      </button>*/}
      <Tabs selectedIndex={tabIndex} onSelect={(index) => {
        setTabIndex(index)
        window.scroll({ behavior: 'smooth', top: 0 })
      }
      }>
        <TabList>
          {/* START LEFT MENU CONTENT */}

          <div className="leftpart">
            {window.innerWidth > 700 &&
              <ParticlesBg />}
            <div className="leftpart_inner">
              <div className="logo">
                <Link className="navbar-brand" to="/">
                  <img className="navbarLogo" src="/assets/img/logo/UtaLogoNeu.svg" alt="brand" />
                  <img className="navbarLogoSmall" src="/assets/img/logo/2Logo.svg" alt="brand" />
                  <img className="navbarLogoSmallText" src="/assets/img/logo/text.svg" alt="brand" />
                </Link>
              </div>
              {/* END LOGO */}

              <div className="menu">
                <ul>
                  <Tab>
                    <img
                      className="svg"
                      src="/assets/img/svg/home-run.svg"
                      alt="homerun"
                    />
                    <span className="menu_content">Home</span>
                  </Tab>
                  <Tab>
                    <img
                      className="svg"
                      src="/assets/img/svg/avatar.svg"
                      alt="avatar"
                    />

                    <span className="menu_content">Über mich</span>


                  </Tab>
                  <Tab>
                    <img
                      className="svg"
                      src="/assets/img/svg/setting.svg"
                      alt="avatar"
                    />
                    <span className="menu_content">Leistungen</span>
                  </Tab>
                  {/*<Tab>
                    <img
                      className="svg"
                      src="/assets/img/svg/briefcase.svg"
                      alt="briefcase"
                    />
                    <span className="menu_content">Portfolio</span>
                  </Tab>*/}
                  {/*   <Tab>
                    <img
                      className="svg"
                      src="/assets/img/svg/paper.svg"
                      alt="paper"
                    />
                    <span className="menu_content">News</span>
                  </Tab>*/}
                  <Tab>
                    <img
                      className="svg"
                      src="/assets/img/svg/mail.svg"
                      alt="mail"
                    />
                    <span className="menu_content">Kontakt</span>
                  </Tab>
                  {window.innerWidth > 700 &&
                    <Tab>
                      <img
                        className="svg"
                        src="/assets/img/svg/shield-fill-check.svg"
                        alt="key"
                      />
                      <span className="menu_content">Datenschutz</span>
                    </Tab>
                  }
                </ul>
              </div>
              {/* END MENU */}

              <CopyRight />
              {/* END COPYRIGHT */}
            </div>
          </div>
          {/* END LEFT MENU CONTENT */}
        </TabList>
        {/* END SIDEBAR TABLIST */}

        {/* START RIGHT PART CONTENT */}
        <div className="rightpart">
          {/* <ParticlesBg /> */}
          <div className="rightpart_in">
            <div className="tokyo_tm_section">
              <TabPanel>
                {window.innerWidth <= 700 &&
                  <ParticlesBg json={params.headerMobile} />}
                <div data-aos="fade-right" data-aos-duration="1200">
                  <Home setTabIndex={setTabIndex} />
                </div>
              </TabPanel>
              {/* END HOME MENU TAB CONTENT */}

              <TabPanel>
                <div
                  data-aos="fade-right"
                  data-aos-duration="1200"
                  data-aos-delay="100"
                >
                  <About />
                </div>
              </TabPanel>
              {/* END ABOUT MENU TAB CONTENT */}

              <TabPanel>
                {window.innerWidth <= 700 &&
                  <ParticlesBg />}
                <div
                  data-aos="fade-right"
                  data-aos-duration="1200"
                  data-aos-delay="100"
                >

                  <ServiceMain />
                </div>
              </TabPanel>
              {/* END ABOUT MENU TAB CONTENT */}

              {/* <TabPanel>
                <Portfolio/>
              </TabPanel>*/}
              {/* END PORTFOLIO MENU TAB CONTENT */}

              {/*  <TabPanel>
                <News />
              </TabPanel>*/}
              {/* END NEWS MENU TAB CONTENT */}

              <TabPanel>
                <div
                  data-aos="fade-right"
                  data-aos-duration="1200"
                  data-aos-delay="200"
                >
                  <Contact />
                </div>
              </TabPanel>

              <TabPanel>
                <div
                  data-aos="fade-right"
                  data-aos-duration="1200"
                  data-aos-delay="200"
                >
                  <DataSec />
                </div>
              </TabPanel>

              {/* END CONTACT MENU TAB CONTENT */}
            </div>
          </div>
        </div>
        {/* END RIGHT PART CONTENT */}
      </Tabs>
      {/* END TABS */}
    </>
  );
};

export default HomeLight;
