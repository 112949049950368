import React, { useEffect } from "react";
import Social from "./Social";
import { ParticlesBg } from "../components/ParticlesBG";
import { saveAccess } from "./service/Analytics";

const Home = (props) => {
  useEffect(() => {
    const previousPage = document.referrer;
    console.log(previousPage)
    saveAccess('Home')
  }, [])
  return (
    <>
      <div className="tokyo_tm_home">
        <div className="home_content">

          <div className="avatar">
            <div
              className="image avatar_img"
              style={{
                backgroundImage: "url(assets/img/slider/NeuesPortrait.jpeg)",
              }}
            ></div>
            {/* END AVATAR IMAGE */}
          </div>
          {/* END AVATAR */}
          <div className="details">
            <h1 style={{ color: 'rgb(0, 153, 153)', fontSize: "38px !important" }} className="name">UTA KOßMAGK</h1>
            <p className="job" style={{ fontWeight: 'bold' }}>
              selbstständige Autorin,
              Lektorin und Korrektorin
              für Buch- und Zeitschriften-Verlage
            </p>
            <div className="tokyo_tm_button" data-position="left">
              <a href="tel:+(491)716422047" >
                <span>Anrufen</span>
                <img style={{ height: 20, marginLeft: 8 }} src="/assets/img/svg/phone.svg" alt="callIcon" />
              </a>
            </div>
            {/* END JOB */}
            {/*<Social />*/}
          </div>
          {/* END DETAILS */}
        </div>
        {/* END HOME CONTENT */}

        <div style={{ position: 'absolute', bottom: (window.innerWidth > 400 ? '1rem' : '3rem'), cursor: 'pointer' }}
          onClick={() => {
            props.setTabIndex(4)
          }}
        >
          Datenschutz
        </div>
      </div>
      {/* END HOME */}
    </>
  );
};

export default Home;
