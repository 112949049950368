import axios from "axios"
import React, { useEffect, useState } from "react"
import { environment } from "../environment"

export function Admin() {
    const [data, setData] = useState()
    const [sourceData, setSourceData] = useState()
    const [loggedIn, setLoggedIn] = useState(false)
    const [password, setPassword] = useState("")
    const [passwordError, setPasswordError] = useState(false)
    useEffect(() => {
        axios.get(environment.backendUrl + "getAccess").then(res => {
            setData(res.data)
        })

        axios.get(environment.backendUrl + "getAccessSources").then(res => {
            setSourceData(res.data.map(data => { return { ...data, reference: data.reference === "" ? "Direktaufruf" : data.reference } }))
        })
    }, [])

    function getDataForPage(pageName) {
        const page = data.find(page => page.page === pageName)
        return page ? page : { total_count: 0, last_week_count: 0, prev_week_count: 0, last_month_count: 0, current_month_count: 0 }
    }

    function getSourceDataForPage(reference) {
        const page = sourceData.find(page => page.reference === reference)
        return page ? page : { total_count: 0, last_week_count: 0, prev_week_count: 0, last_month_count: 0, current_month_count: 0 }
    }

    function logIn() {
        console.log(password)
        if (password === "RobertK2001") {
            setLoggedIn(true)
            setPasswordError(false)
        } else {
            setPasswordError(true)
        }
    }

    return (
        <div className="adminTable">
            {loggedIn ?
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>Info</th>
                                <th>Home</th>
                                <th>Über mich</th>
                                <th>Leistungen</th>
                                <th>Kontakt</th>
                                <th>Datenschutz</th>
                            </tr>
                        </thead>
                        {data?.length &&
                            <tbody>
                                <tr>
                                    <td>Insgesamt</td>
                                    <td>{getDataForPage("Home").total_count}</td>
                                    <td>{getDataForPage("Über mich").total_count}</td>
                                    <td>{getDataForPage("Leistungen").total_count}</td>
                                    <td>{getDataForPage("Kontakt").total_count}</td>
                                    <td>{getDataForPage("Datenschutz").total_count}</td>
                                </tr>
                                <tr>
                                    <td>Letzte 7 Tage</td>
                                    <td>{getDataForPage("Home").last_week_count}</td>
                                    <td>{getDataForPage("Über mich").last_week_count}</td>
                                    <td>{getDataForPage("Leistungen").last_week_count}</td>
                                    <td>{getDataForPage("Kontakt").last_week_count}</td>
                                    <td>{getDataForPage("Datenschutz").last_week_count}</td>
                                </tr>
                                <tr>
                                    <td>Letzte 14 Tage (ohne die Letzten 7 Tage)</td>
                                    <td>{getDataForPage("Home").prev_week_count}</td>
                                    <td>{getDataForPage("Über mich").prev_week_count}</td>
                                    <td>{getDataForPage("Leistungen").prev_week_count}</td>
                                    <td>{getDataForPage("Kontakt").prev_week_count}</td>
                                    <td>{getDataForPage("Datenschutz").prev_week_count}</td>
                                </tr>
                                <tr>
                                    <td>Letzter Monat</td>
                                    <td>{getDataForPage("Home").last_month_count}</td>
                                    <td>{getDataForPage("Über mich").last_month_count}</td>
                                    <td>{getDataForPage("Leistungen").last_month_count}</td>
                                    <td>{getDataForPage("Kontakt").last_month_count}</td>
                                    <td>{getDataForPage("Datenschutz").last_month_count}</td>
                                </tr>
                                <tr>
                                    <td>Aktueller Monat</td>
                                    <td>{getDataForPage("Home").current_month_count}</td>
                                    <td>{getDataForPage("Über mich").current_month_count}</td>
                                    <td>{getDataForPage("Leistungen").current_month_count}</td>
                                    <td>{getDataForPage("Kontakt").current_month_count}</td>
                                    <td>{getDataForPage("Datenschutz").current_month_count}</td>
                                </tr>
                            </tbody>
                        }
                    </table>
                    {sourceData?.length &&
                        <table>
                            <thead>
                                <tr>
                                    <th>Info</th>
                                    {sourceData.map(data => {
                                        return (<th>{data.reference}</th>)
                                    })}
                                </tr>
                            </thead>
                            {data?.length &&
                                <tbody>
                                    <tr>
                                        <td>Insgesamt</td>
                                        {sourceData.map(data => {
                                            return (<td>{getSourceDataForPage(data.reference).total_count}</td>)
                                        })}
                                    </tr>
                                    <tr>
                                        <td>Letzte 7 Tage</td>
                                        {sourceData.map(data => {
                                            return (<td>{getSourceDataForPage(data.reference).last_week_count}</td>)
                                        })}
                                    </tr>
                                    <tr>
                                        <td>Letzte 14 Tage (ohne die Letzten 7 Tage)</td>
                                        {sourceData.map(data => {
                                            return (<td>{getSourceDataForPage(data.reference).prev_week_count}</td>)
                                        })}
                                    </tr>
                                    <tr>
                                        <td>Letzter Monat</td>
                                        {sourceData.map(data => {
                                            return (<td>{getSourceDataForPage(data.reference).last_month_count}</td>)
                                        })}
                                    </tr>
                                    <tr>
                                        <td>Aktueller Monat</td>
                                        {sourceData.map(data => {
                                            return (<td>{getSourceDataForPage(data.reference).current_month_count}</td>)
                                        })}
                                    </tr>
                                </tbody>
                            }
                        </table>}
                </div>
                : <div className="loginContainer">
                    <div class="login-container">
                        <div class="login-title">Login</div>
                        <input class="password-input" type="password" placeholder="Passwort" onChange={(text) => setPassword(text.target.value)} id="password" />
                        <button class="login-button" onClick={() => { logIn() }}>Einloggen</button>
                        {passwordError &&
                            <div style={{ color: 'red' }}>Passwort falsch</div>}
                    </div>
                </div>
            }

        </div>
    )
}