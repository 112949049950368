import React from "react";

const Intro = () => {
  const introContent = {
    image: "assets/img/slider/buerobild.jpeg",
    name: "Uta Koßmagk",
    designation: (<p style={{ marginTop: 11 }}>selbstständige <span style={{ color: "var(--pink)" }}> Autorin, Lektorin </span> und <span style={{ color: "var(--pink)" }}>Korrektorin</span> für Buch- und Zeitschriften-Verlage</p>),
    text: (
      <div>
        <p>
          <div style={{ fontSize: 18, marginBottom: 8 }}>Hallo,</div>ich heiße Uta Koßmagk und arbeite seit 17 Jahren als selbstständige Autorin, Lektorin und Korrektorin für
          verschiedene Buch- und Zeitschriften-Verlage.
          Zuvor habe ich einige Jahre in der Redaktion eines großen Ratgeberverlags gearbeitet.
        </p>
      </div>
    ),
  };

  return (
    <>
      <div className="top_author_image">
        <img src={introContent.image} alt="about" />
      </div>
      <div className="about_title">
        <h3 style={{ color: "var(--primary)" }} >{introContent.name}</h3>
        <span style={{ fontWeight: 'bold', }}>{introContent.designation}</span>
      </div>
      <div style={{ width: '100%' }} className="about_text">
        <div style={{ width: '70%' }}>{introContent.text}</div>
      </div>
      <div className="block"></div>
    </>
  );
};

export default Intro;
