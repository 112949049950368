import React, { useEffect } from "react";
import Modal from "react-modal";
import Testimonial from "./Testimonial";
import Intro from "./Intro";
import KnowledgeInterest from "./KnowledgeInterest";
import PersonalInfo from "./PersonalInfo";
import Resume from "./Resume";
import Skills from "./Skills";
import { environment } from "../../environment";
import axios from "axios";
import { saveAccess } from "../service/Analytics";

Modal.setAppElement("#root");

const AboutMain = () => {



  useEffect(() => {
    saveAccess("Über mich")
  }, [])

  return (
    <>
      <div className="container">
        <div className="tokyo_tm_about">
          <div className="tokyo_tm_title">
            <div className="title_flex">
              <div className="left">
                <h2>Über mich</h2>
              </div>
            </div>
          </div>
          {/* End title */}

          <Intro />
          {/* <div className="tokyo_tm_short_info">
            <PersonalInfo />
          </div> */}
          {/* End personal info */}

          <div className="vCardButton" data-position="left">
            <a href={environment.backendUrl + "vcard"} download>
              {/* <a href="http://localhost:8081/vcard" download> */}
              <span>Meine Visitenkarte</span>
              <img style={{ height: 20, marginLeft: 8 }} src="/assets/img/svg/vcard.svg" alt="callIcon" />
            </a>
          </div>
        </div>
      </div>
      {/* End .container */}

      {/*    <div className="tokyo_tm_progressbox">
        <div className="container">
          <div className="in">
            <Skills />
          </div>
           End in
        </div>
         End .container
      </div>
       End tokyo_tm_progressbox */}

      {/*     <div className="tokyo_tm_skillbox">
        <div className="container">
          <div className="in">
            <KnowledgeInterest />
          </div>
        </div>
      </div>
       End .tokyo_tm_skillbox */}

      <div className="tokyo_tm_resumebox">
        <div className="container">
          <div className="in">
            <Resume />
          </div>
        </div>
      </div>
      {/* End tokyo_tm_resumebox */}

      {/* <div className="tokyo_tm_testimonials">
        <div className="container">
          <div className="tokyo_section_title">
            <h3>Testimonials</h3>
          </div>
          <div className="list">
            <Testimonial />
          </div>
        </div>
      </div> */}
      {/* End tokyo_tm_testimonials */}

      {/* /ABOUT */}
    </>
  );
};

export default AboutMain;
