import React, { useState } from "react";
import Modal from "react-modal";
import Social from "../Social";

Modal.setAppElement("#root");

const Services = () => {
  const [modalNumber, setModalNumber] = useState(0);

  const serviceContent = [
    {
      id: 1,
      no: "1",
      title: "komplette redaktionelle Betreuung von Buch- oder Zeitschriftenprojekten",
      text: `Zur Betreuung eines Buch- oder Zeitschriftenprojektes von der Idee bis zum fertigen Produkt ...`,
    },
    {
      id: 2,
      no: "2",
      title: "Firmenporträts",
      text: (<>Ein Firmenporträt ist einer der wichtigsten Texte im Außen&shy;auftritt eines Unter&shy;nehmens ...</>),
      // text: "Ein Firmenporträt ist einer der wichtigsten Texte im Außen&shy;auftritt eines Unter&shy;nehmens ...",
    },

    {
      id: 4,
      no: "3",
      title: "Reportagen",
      text: `Für ein 6-mal im Jahr erscheinendes Kreativmagazin zu den Themen Malen und Zeichnen ...`,
    },
    {
      id: 3,
      no: "4",
      title: "Künstlerporträts",
      text: `Ebenfalls für das 6-mal im Jahr erscheinende Kreativmagazin ...`,
    },
    {
      id: 5,
      no: "5",
      title: "Lektorat",
      text: `Beim Lektorat geht es um die rechtschreibliche, stilistische, grammatikalische und inhaltliche Verbesserung von Texten ...`,
    },
    {
      id: 6,
      no: "6",
      title: "Korrektorat",
      text: `Bei einem Korrektorat geht es vor allem um die sprachliche Richtigkeit eines Textes ...`,
    },
  ];

  return (
    <>
      {serviceContent.map((item) => (
        <li key={item.id}>
          <div className="list_inner">
            <span className="number">{item.no}</span>
            <h3 className="title">{item.title}</h3>
            <p className="text">{item.text}</p>
            <div className="tokyo_tm_read_more">
              <a href="#" onClick={() => { setModalNumber(item.id) }}>
                <span>Mehr</span>
              </a>
            </div>
          </div>
        </li>
      ))}

      {/* START MODAL */}
      <Modal
        isOpen={modalNumber === 1}
        onRequestClose={() => { setModalNumber(0) }}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news">
          <button className="close-modal" onClick={() => { setModalNumber(0) }}>
            <img src="assets/img/svg/x-circle.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image" style={{ height: '250px' }}>
                <img src="assets/img/thumbs/4-3.jpg" alt="tumb" />
                <div
                  className="main"
                  style={{
                    backgroundImage: "url(assets/img/services/magazine.jpg)",
                  }}
                ></div>
              </div>
              {/* END IMAGE */}
              <div className="details">
                <div className="extra">
                  {/* <p className="date">
                    By <a href="#">Alex Watson</a>
                    <span>05 April 2021</span>
                  </p>*/}
                </div>
                <h3 className="title">
                  Komplette redaktionelle Betreuung von Buch- oder Zeitschriftenprojekten
                </h3>
              </div>
              {/* END DETAILS */}
              <div className="main_content ">
                <div className="descriptions">
                  <p className="bigger">
                    Zur Betreuung eines Buch- oder Zeitschriftenprojektes von der Idee bis zum fertigen Produkt gehört das Lektorieren des Manuskriptes,
                    die Planung des Layouts und der Bebilderungen (Zeichnungen, Fotos, Illustrationen, Vorlagen) und in diesem Zusammenhang
                    die Akquise bzw. Zusammenarbeit mit entweder ebenfalls frei arbeitenden oder fest angestellten Grafikern, mit den Autoren, Illustratoren, Fotografen,
                    Zeichnern und Producern. Parallel erarbeite ich Vorschläge für die Titelformulierung und die Umschlaggestaltung der von mir betreuten Projekte,
                    erstelle Grundlagentexte für Werbe- und PR-Maßnahmen und formuliere Klappen- und Rückseitentexte.
                    Nach erfolgter Erstellung des Umbruchs vom Buch- oder Zeitschriftenprojekt prüfe ich das Zusammenspiel von Text und Bild und lese Korrektur.
                  </p>
                  {/*<div className="quotebox">
                    <div className="icon">
                      <img
                        className="svg"
                        src="assets/img/svg/quote.svg"
                        alt="tumb"
                      />
                    </div>
                    <p>
                      Most photographers find it hard to see interesting
                      pictures in places in which they are most familiar. A trip
                      somewhere new seems always exactly what our photography
                      needed, as shooting away from home consistently inspires
                      us to new artistic heights.
                    </p>
                  </div>*/}
                  {/* END QUOTEBOX */}
                </div>
                {/* END DESCRIPTION */}
                {/*   <div className="news_share">
                  <span>Share:</span>
                  <Social />
                </div>*/}
                {/* END NEWS SHARE */}
              </div>
            </div>
          </div>
          {/* END BOX INNER */}
        </div>
        {/* END MODALBOX NEWS */}
      </Modal>
      {/* END MODAL */}
      {/* START MODAL */}
      <Modal
        isOpen={modalNumber === 2}
        onRequestClose={() => { setModalNumber(0) }}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news">
          <button className="close-modal" onClick={() => { setModalNumber(0) }}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image">
                <img src="assets/img/thumbs/4-3.jpg" alt="tumb" />
                <div
                  className="main"
                  style={{
                    backgroundImage: "url(assets/img/services/frankfurt1.jpg)",
                  }}
                ></div>
              </div>
              {/* END IMAGE */}
              <div className="details">
                <div className="extra">
                  {/* <p className="date">
                    By <a href="#">Alex Watson</a>
                    <span>05 April 2021</span>
                  </p>*/}
                </div>
                <h3 className="title">
                  Firmenporträts
                </h3>
              </div>
              {/* END DETAILS */}
              <div className="main_content ">
                <div className="descriptions">
                  <p className="bigger">
                    Ein Firmenporträt ist einer der wichtigsten Texte im Außenauftritt eines Unternehmens.
                    Mit ihm vermittelt das Unternehmen den potenziellen Kunden und Geschäftspartnern, was sie tun,
                    wofür sie stehen und welche Services sie anbieten. Im Auftrag einer monatlich erscheinenden Handarbeitszeitschrift
                    schreibe ich regelmäßig solche Firmenporträts, sei es für Handarbeitsgeschäfte,
                    Online-Anbieter im Bereich Handarbeiten oder Handarbeitsfirmen.
                  </p>
                  {/*<div className="quotebox">
                    <div className="icon">
                      <img
                        className="svg"
                        src="assets/img/svg/quote.svg"
                        alt="tumb"
                      />
                    </div>
                    <p>
                      Most photographers find it hard to see interesting
                      pictures in places in which they are most familiar. A trip
                      somewhere new seems always exactly what our photography
                      needed, as shooting away from home consistently inspires
                      us to new artistic heights.
                    </p>
                  </div>*/}
                  {/* END QUOTEBOX */}
                </div>
                {/* END DESCRIPTION */}
                {/*   <div className="news_share">
                  <span>Share:</span>
                  <Social />
                </div>*/}
                {/* END NEWS SHARE */}
              </div>
            </div>
          </div>
          {/* END BOX INNER */}
        </div>
        {/* END MODALBOX NEWS */}
      </Modal>
      {/* END MODAL */}
      {/* START MODAL */}
      <Modal
        isOpen={modalNumber === 3}
        onRequestClose={() => { setModalNumber(0) }}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news">
          <button className="close-modal" onClick={() => { setModalNumber(0) }}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image">
                <img src="assets/img/thumbs/4-3.jpg" alt="tumb" />
                <div
                  className="main"
                  style={{
                    backgroundImage: "url(assets/img/services/painter2.jpg)",
                  }}
                ></div>
              </div>
              {/* END IMAGE */}
              <div className="details">
                <div className="extra">
                  {/* <p className="date">
                    By <a href="#">Alex Watson</a>
                    <span>05 April 2021</span>
                  </p>*/}
                </div>
                <h3 className="title">
                  Künstlerporträts
                </h3>
              </div>
              {/* END DETAILS */}
              <div className="main_content ">
                <div className="descriptions">
                  <p className="bigger">
                    Ebenfalls für das 6-mal im Jahr erscheinende Kreativmagazin schreibe ich in jeder Ausgabe
                    ein mehrseitiges Porträt über eine ausgewählte Künstlerin  oder einen Künstler.
                  </p>
                  {/*<div className="quotebox">
                    <div className="icon">
                      <img
                        className="svg"
                        src="assets/img/svg/quote.svg"
                        alt="tumb"
                      />
                    </div>
                    <p>
                      Most photographers find it hard to see interesting
                      pictures in places in which they are most familiar. A trip
                      somewhere new seems always exactly what our photography
                      needed, as shooting away from home consistently inspires
                      us to new artistic heights.
                    </p>
                  </div>*/}
                  {/* END QUOTEBOX */}
                </div>
                {/* END DESCRIPTION */}
                {/*   <div className="news_share">
                  <span>Share:</span>
                  <Social />
                </div>*/}
                {/* END NEWS SHARE */}
              </div>
            </div>
          </div>
          {/* END BOX INNER */}
        </div>
        {/* END MODALBOX NEWS */}
      </Modal>
      {/* END MODAL */}
      {/* START MODAL */}
      <Modal
        isOpen={modalNumber === 4}
        onRequestClose={() => { setModalNumber(0) }}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news">
          <button className="close-modal" onClick={() => { setModalNumber(0) }}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image">
                <img src="assets/img/thumbs/4-3.jpg" alt="tumb" />
                <div
                  className="main"
                  style={{
                    backgroundImage: "url(assets/img/services/painter1.jpg)",
                  }}
                ></div>
              </div>
              {/* END IMAGE */}
              <div className="details">
                <div className="extra">
                  {/* <p className="date">
                    By <a href="#">Alex Watson</a>
                    <span>05 April 2021</span>
                  </p>*/}
                </div>
                <h3 className="title">
                  Reportagen
                </h3>
              </div>
              {/* END DETAILS */}
              <div className="main_content ">
                <div className="descriptions">
                  <p className="bigger">
                    Für ein 6-mal im Jahr erscheinendes Kreativmagazin zu den Themen
                    Malen und Zeichnen verfasse ich für jede Ausgabe eine Reise-Reportage für Kreativ-Reisen.
                  </p>
                  {/*<div className="quotebox">
                    <div className="icon">
                      <img
                        className="svg"
                        src="assets/img/svg/quote.svg"
                        alt="tumb"
                      />
                    </div>
                    <p>
                      Most photographers find it hard to see interesting
                      pictures in places in which they are most familiar. A trip
                      somewhere new seems always exactly what our photography
                      needed, as shooting away from home consistently inspires
                      us to new artistic heights.
                    </p>
                  </div>*/}
                  {/* END QUOTEBOX */}
                </div>
                {/* END DESCRIPTION */}
                {/*   <div className="news_share">
                  <span>Share:</span>
                  <Social />
                </div>*/}
                {/* END NEWS SHARE */}
              </div>
            </div>
          </div>
          {/* END BOX INNER */}
        </div>
        {/* END MODALBOX NEWS */}
      </Modal>
      {/* END MODAL */}
      {/* START MODAL */}
      <Modal
        isOpen={modalNumber === 5}
        onRequestClose={() => { setModalNumber(0) }}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news">
          <button className="close-modal" onClick={() => { setModalNumber(0) }}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image">
                <img src="assets/img/thumbs/4-3.jpg" alt="tumb" />
                <div
                  className="main"
                  style={{
                    backgroundImage: "url(assets/img/services/books.jpg)",
                    backgroundPosition: 'center',

                  }}
                ></div>
              </div>
              {/* END IMAGE */}
              <div className="details">
                <div className="extra">
                  {/* <p className="date">
                    By <a href="#">Alex Watson</a>
                    <span>05 April 2021</span>
                  </p>*/}
                </div>
                <h3 className="title">
                  Lektorat
                </h3>
              </div>
              {/* END DETAILS */}
              <div className="main_content ">
                <div className="descriptions">
                  <p className="bigger">
                    Beim Lektorat geht es um die rechtschreibliche, stilistische, grammatikalische und inhaltliche Verbesserung von Texten.
                    Gegebenenfalls kann es auch nötig sein, den Aufbau, die Gliederung,
                    Kapitelunterteilungen oder den Kapitelaufbau zu überarbeiten.
                    Ich lektoriere vor allem Buchmanuskripte verschiedener Verlage im Kreativbuch-, Ratgeber- und Sachbuchbereich.
                  </p>
                  {/*<div className="quotebox">
                    <div className="icon">
                      <img
                        className="svg"
                        src="assets/img/svg/quote.svg"
                        alt="tumb"
                      />
                    </div>
                    <p>
                      Most photographers find it hard to see interesting
                      pictures in places in which they are most familiar. A trip
                      somewhere new seems always exactly what our photography
                      needed, as shooting away from home consistently inspires
                      us to new artistic heights.
                    </p>
                  </div>*/}
                  {/* END QUOTEBOX */}
                </div>
                {/* END DESCRIPTION */}
                {/*   <div className="news_share">
                  <span>Share:</span>
                  <Social />
                </div>*/}
                {/* END NEWS SHARE */}
              </div>
            </div>
          </div>
          {/* END BOX INNER */}
        </div>
        {/* END MODALBOX NEWS */}
      </Modal >
      {/* END MODAL */}
      {/* START MODAL */}
      <Modal
        isOpen={modalNumber === 6}
        onRequestClose={() => { setModalNumber(0) }}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news">
          <button className="close-modal" onClick={() => { setModalNumber(0) }}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image">
                <img src="assets/img/thumbs/4-3.jpg" alt="tumb" />
                <div
                  className="main"
                  style={{
                    backgroundImage: "url(assets/img/services/glasses.jpg)",
                    backgroundPosition: "bottom"
                  }}
                ></div>
              </div>
              {/* END IMAGE */}
              <div className="details">
                <div className="extra">
                  {/* <p className="date">
                    By <a href="#">Alex Watson</a>
                    <span>05 April 2021</span>
                  </p>*/}
                </div>
                <h3 className="title">
                  Korrektorat
                </h3>
              </div>
              {/* END DETAILS */}
              <div className="main_content ">
                <div className="descriptions">
                  <p className="bigger">
                    Bei einem Korrektorat geht es vor allem um die sprachliche Richtigkeit eines Textes, d.h.,
                    ich prüfe ein Manuskript oder ein fertig gesetztes Layout vor allem hinsichtlich Rechtschreibung, Grammatik, Satzbau und Zeichensetzung
                    und korrigiere gegebenenfalls. Bei der Prüfung achte ich zudem auf den einheitlichen Gebrauch von Schreibweisen und Abkürzungen,
                    unnötige Wortwiederholungen sowie auf Vollständigkeit der Sätze.
                  </p>
                  {/*<div className="quotebox">
                    <div className="icon">
                      <img
                        className="svg"
                        src="assets/img/svg/quote.svg"
                        alt="tumb"
                      />
                    </div>
                    <p>
                      Most photographers find it hard to see interesting
                      pictures in places in which they are most familiar. A trip
                      somewhere new seems always exactly what our photography
                      needed, as shooting away from home consistently inspires
                      us to new artistic heights.
                    </p>
                  </div>*/}
                  {/* END QUOTEBOX */}
                </div>
                {/* END DESCRIPTION */}
                {/*   <div className="news_share">
                  <span>Share:</span>
                  <Social />
                </div>*/}
                {/* END NEWS SHARE */}
              </div>
            </div>
          </div>
          {/* END BOX INNER */}
        </div>
        {/* END MODALBOX NEWS */}
      </Modal>
      {/* END MODAL */}
    </>
  );
};

export default Services;
