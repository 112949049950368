import React from "react";

const Resume = () => {
  const resumeContent = [
    {
      id: 1,
      colClass: "left",
      title: "Arbeit als Autorin",
      resume: [
        {
          id: 1,
          year: "2014 - 2016",
          institute: "Für Kindersachbuch- und Kreativbuchverlag",
          degree: "ca. 25 Anleitungshefte",
        },
        {
          id: 2,
          year: "2010 - 2014",
          institute: "Für Kreativbuch-Verlag",
          degree: "4 Bücher",
        },
        {
          id: 3,
          year: "2008 - 2010",
          institute: "Für Online-Portale",
          // degree: "ca. 60 Fachartikel zu Krankheiten und deren Behandlungsmöglichkeiten",
          degree: (<p style={{ width: 300 }}>ca. 60 Fachartikel zu Krankheiten <br></br> und deren Behandlungsmöglichkeiten</p>),
        },
      ],
    },
    {
      id: 2,
      colClass: "right",
      title: "Arbeit als Lektorin",
      resume: [
        {
          id: 1,
          year: "2018 - Now",
          institute: "Fachbuchverlag für Pädagogen",
          degree: "ca. 35 Bücher",
        },
        {
          id: 2,
          year: "2016 - 2018",
          institute: "Kindersachbuch-Verlag",
          degree: (<>ca. 35 Anleitungshefte<br></br>1 Kindersachbuch </>),
        },
        {
          id: 3,
          year: "2012 - 2016",
          institute: "Für Zeitschriftenverlag im Kreativbereich",
          degree: (<>ca. 400 Zeitschriften<br></br>10 Bastelbücher </>),
        },
        {
          id: 4,
          year: "2012 - 2016",
          institute: "Kreativbuch-Verlag",
          degree: "ca. 35 Bücher",
        },
        {
          id: 5,
          year: "2012 - 2016",
          institute: "Für Sach- und Naturbuch-Verlag",
          degree: "ca. 20 Bücher",
        },
      ],
    }
  ];
  return (
    <>
      {resumeContent.map((item) => (
        <div className={item.colClass} key={item.id}>
          <div>
            <div className="tokyo_section_title">
              <h3>{item.title}</h3>
            </div>
            <div className="tokyo_tm_resume_list">
              <ul>
                {item?.resume?.map((value) => (
                  <li key={value.id}>
                    <div className="list_inner">
                      <div className="place">
                        <h3>{value.institute}</h3>
                        <span className="smallText">{value.degree}</span>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>

        </div>
      ))}
    </>
  );
};

export default Resume;
