import React, { useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import { params } from './jsons'

export function ParticlesBg(props) {
    const particlesInit = useCallback(async engine => {
        await loadFull(engine);
    }, []);
    const particlesLoaded = useCallback(async container => {
    }, []);
    return (
        <Particles id={props.id} height={'100%'} width={'100%'} fullScreen={false} style={{ zIndex: 0, position: 'absolute', height: '100%', width: '100%' }} options={props.json ?? params.header} init={particlesInit} loaded={particlesLoaded} />
    );
};