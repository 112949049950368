import React, { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Map from "./Map";
import { setAppElement } from "react-modal";
import axios from "axios";
import { environment } from "../environment";
import { saveAccess } from "./service/Analytics";

const Contact = () => {

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [tel, setTel] = useState('')
  const [message, setMessage] = useState('')

  useEffect(() => {
    const previousPage = document.referrer;
    saveAccess("Kontakt")
  }, [])

  const sendEmail = (e) => {
    e.preventDefault();
    // #009999 #693F2B #EFE7DD #F652A0 rgba(0, 153, 153, 0.05)
    axios.post(environment.backendUrl + 'emailMe', {
      // axios.post('http://localhost:8080/emailMe', {
      name: name, email: email, phone: tel, message: message
    }).then(res => {
      toast.success("Nachricht erfolgreich versendet!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      resetForm()
    }).catch(err => {
      toast.error("Ups, das hat nicht geklappt. Versuche es bitte erneut!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
  };

  function resetForm() {
    setName('')
    setEmail('')
    setTel('')
    setMessage('')
  }

  return (
    <>
      <div className="container">
        <div className="tokyo_tm_contact">
          <div className="tokyo_tm_title">
            <div className="title_flex">
              <div className="left">
                <span>Kontakt</span>
                <h2>Kontaktformular</h2>
              </div>
            </div>
          </div>
          {/* END TITLE */}
          {/*
          <Map />*/}
          {/* MENU WRAP */}

          <div className="fields">
            <form
              className="contact_form"
              id="myForm"
              onSubmit={sendEmail}
            >
              <div className="first">
                <ul>
                  <li>
                    <div>Name</div>
                    <input
                      type="text"
                      name="name"
                      required
                      value={name}
                      onChange={(text) => { console.log('doöifhdiofhio'); setName(text.target.value) }}
                    />
                  </li>
                  {/* END FIRST NAME */}

                  <li>
                    <div>Email</div>
                    <input
                      type="email"
                      name="user_email"
                      // placeholder="Email"
                      required
                      value={email}
                      onChange={(text) => setEmail(text.target.value)}
                    />
                  </li>
                  <li>
                    <div>Tel.</div>
                    <input
                      type="tel"
                      name="tel"
                      // placeholder="Telefon"
                      required
                      value={tel}
                      onChange={(text) => setTel(text.target.value)}
                    />
                  </li>
                  {/* END EMAIL */}

                  <li>
                    <div>Nachricht</div>
                    <textarea
                      name="message"
                      // placeholder="Nachricht"
                      required
                      value={message}
                      onChange={(text) => setMessage(text.target.value)}
                    ></textarea>
                  </li>
                  {/* END SUBJECT MESSAGE */}
                </ul>
              </div>
              <div className="tokyo_tm_button">
                <button type="submit" className="ib-button">
                  Nachricht senden
                </button>
              </div>
              {/* END SUBMIT BUTTON */}
            </form>
            {/* END FORM */}
          </div>
          {/* END FIELDS */}
        </div>
      </div>
      {/* END COTACT */}
    </>
  );
};

export default Contact;
